<template>
  <div class="ad-contents">
    <div class="hot-tab">
      <el-button class="add-btn" type="primary" :disabled="addIsDisabled()" @click="add('新增')">新增</el-button>
      <p class="title" v-if="tabContent&&tabContent.isShowNotice">
        <i>*注:</i>
        <span>本页面无法添加含有按钮的banner，若有添加需要请联系管理员，由研发人员修改对应banner代码。</span>
      </p>
      <div class="content">
        <ul>
          <li v-for="(item,index) in adPageList" :key="index">
            <el-image
              class="content-img-box"
              :src="item.ImgURL"
              :preview-src-list="[item.ImgURL]"
              fit="fill">
            </el-image>
            <p>
              <span class="text-name"><i class="hot-icon">*</i>标题：</span>{{item.Name}}
            </p>
            <p>
              <span class="text-name"><i class="hot-icon">*</i>顺序：</span>{{ item.Sort }}
            </p>
            <p>
              <span class="text-name"><i v-if="[0,21,22].includes(item.Type)" class="hot-icon">*</i>链接：</span>
              <el-tooltip v-if="item.URL" class="item" effect="dark" :content="item.URL" placement="right">
                <a class="url" :href="item.URL">{{item.URL}}</a>
              </el-tooltip>
              <span v-else>无</span>
            </p>
            <p>
              <span class="text-name">编辑时间：</span>{{ item.UpdateTime }}
            </p>
            <div class="button">
              <el-button type="danger" size="small" :disabled="delIsDisabled(item)" @click="del(item)">删除</el-button>
              <el-button type="primary" size="small" @click="add('编辑',item)">编辑</el-button>
            </div>
          </li>
        </ul>
      </div>
      <!-- 编辑/新增 -->
      <div class="formdia" v-if="tabContent">
        <el-dialog
          custom-class="formdia-dialog"
          :visible.sync="dialogFormVisible"
          :title="tabContent.name+'-'+dialogFormTitle"
          :show-close="false">
          <el-form ref="formdiaDialogForm" :model="formdiaDialogForm" :rules="formdiaDialogFormRules" label-width="100px">
            <el-form-item label="图片" prop="ImgURL">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :http-request="(data)=>uploadFile(data.file)">
                <img v-if="formdiaDialogForm.ImgURL" :src="formdiaDialogForm.ImgURL" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div style="color:red;line-height:1;">（尺寸：{{tabContent.size}}像素，大小不超过300kb）</div>
            </el-form-item>
            <el-form-item label="标题" prop="Name">
              <el-input v-model="formdiaDialogForm.Name" placeholder="请输入名称" style="width:182px;"></el-input>
            </el-form-item>
            <el-form-item label="顺序" prop="Sort">
              <el-select v-model="formdiaDialogForm.Sort" style="width:182px;">
                <el-option
                  v-for="item in tabContent.maxNum"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="链接" prop="URL" :required="[0,21,22].includes(tabContent.type)">
              <el-input v-model="formdiaDialogForm.URL" placeholder="请输入链接" maxlength="200" style="width:182px;"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="Description" v-if="[21,22].includes(tabContent.type)">
              <el-input v-model="formdiaDialogForm.Description" placeholder="请输入描述" maxlength="200" style="width:182px;"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="resetForm">取消</el-button>
              <el-button type="primary" @click="submitForm">保存</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    tabContent:{
      type:Object,
      default(){
        return null
      }
    }
  },
  data() {
    var validateName = (rule, value, callback) => {
      let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
      if(!value.trim()) {
        callback(new Error('请输入名称'));
      } else if(!reg.test(value)) {
        callback(new Error('名称只允许输入汉字、数字或字母'));
      } else {
        callback();
      }
    };
    var validateSort = (rule, value, callback) => {
      if(!this.adPageList.length){
        callback();
      }
      this.adPageList.filter(item=>{
        if(this.formdiaDialogFormOrigin.Sort!=value&&item.Sort==value) {
          callback(new Error('该排序已存在,请重新选择'));
        } else {
          callback();
        }
      })
    };
    var validateURL = (rule, value, callback) => {
      if([0,21,22].includes(this.tabContent.type)) {
        if(!value.trim()) {
          callback(new Error('请输入链接'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      adPageList:[],
      dialogFormVisible: false,
      dialogFormTitle:null,
      formdiaDialogForm:{
        Name: "",
        Sort: 1,
        URL: "",
        UpdateTime: "",
        ImgURL:"",
        Description:"",
        News:"",
      },
      formdiaDialogFormRules:{
        ImgURL: [{ required: true, message: '请上传图片' }],
        Name:[{ required: true,validator: validateName}],
        Sort:[{ required: true,validator: validateSort}],
        Sort:[{ required: true,message: '请选择栏目'}],
        URL:[{ validator: validateURL}],
        Description:[{ required: true, message: '请输入描述' }]
      },
      formdiaDialogFormOrigin:{},
      newsList:[],
    };
  },
  computed:{
    addIsDisabled() {
      return function() {
          if(!this.tabContent) return
          let disabled=false;
          if(this.adPageList.length>=this.tabContent.maxNum) {
            disabled=true;
          } else {
            disabled=false;
          }
          return disabled
      }
    },
    delIsDisabled() {
      return function() {
          if(!this.tabContent) return
          let disabled=false;
          if(this.adPageList.length<=this.tabContent.min) {
            disabled=true;
          } else {
            disabled=false;
          }
          return disabled
      }
    }
  },
  mounted() {
  },
  methods: {
    initList(type) {
      this.$get(this.$api.GetAppAdsensesList,{
        type:type
      }).then(res=>{
        this.adPageList=res.Data;
      })
    },
    add(type,item) {
      this.dialogFormVisible = true;
      this.dialogFormTitle=type;
      this.formdiaDialogForm.Type=this.tabContent.type;
      if(type=='编辑') {
        this.formdiaDialogForm=JSON.parse(JSON.stringify(item));
        this.formdiaDialogFormOrigin=JSON.parse(JSON.stringify(item));
      }
    },
    uploadFile(file) {
      let nameIndex=file.name.lastIndexOf('.');
      let type=file.name.substring(nameIndex+1,file.name.length);
      var formData = new FormData();
      formData.append("file", file);

      this.$post(this.$api.UploadFileV2+'?module=adsense&fileExpand=.'+type,formData).then(res=>{
        if(res.code==200) {
          this.formdiaDialogForm.ImgURL=res.data;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    submitForm() {
      this.$refs.formdiaDialogForm.validate((valid) => {
        if(!valid) return
        this.$post(this.$api.GetAppAdsensesList,this.formdiaDialogForm).then(res=>{
          if(res.Code==200) {
            this.$message.success(res.Message);
            this.resetForm();
            this.initList(this.tabContent.type);
          } else {
            this.$message.error(res.Message);
          }
        })
      });
    },
    resetForm() {
      this.$refs.formdiaDialogForm.resetFields();
      this.formdiaDialogForm={
        Name: "",
        Sort: 1,
        URL: "",
        UpdateTime: "",
        ImgURL:"",
        Description:""
      };
      this.formdiaDialogFormOrigin={};
      this.dialogFormVisible = false;
    },
    del(item) {
      this.$confirm('确定删除"'+item.Name+'"?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        this.$post(this.$api.DeleteAdSense+'?id='+item.Id).then(res=>{
          if(res.Code==200) {
            this.$message.success(res.Message);
            this.initList(this.tabContent.type);
          } else {
            this.$message.error(res.Message);
          }
        })
      }).catch()
    }
  }
};
</script>

<style lang="stylus" scoped>
.ad-contents {
  button.add-btn {
    margin-left: 20px;
  }
  .hot-tab {
    p.title {
      padding: 10px 0 0 20px;
      i {
        font-style: normal;
        color: #ff5722;
      }
      span {
        padding-left: 10px;
      }
    }
    .content {
      ul {
        padding-top: 20px;
        padding-left: 20px;
        li {
          width: 300px;
          height: 340px;
          border: 1px solid #d1dbe2;
          text-overflow: ellipsis;
          float: left;
          margin-right: 20px;
          margin-bottom: 15px;
          .hot-icon {
            font-style:normal;
            color: #FF5722;
            padding-right: 5px;
          }
          .text-name {
            display: inline-block;
            width:100px;
            text-align: right;
          }
          p {
            display: inline-block;
            width: 280px;
            line-height: 35px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            a:hover {
              color: #777777;
              text-decoration: underline;
            }
          }
          .button {
            text-align:center;
            padding-bottom: 10px;
          }
          .content-img-box {
            display: block;
            margin:8px auto 0;
            width:181px;
            height: 100px;
          }
        }
      }
    }
  }
  .formdia {
    /deep/.formdia-dialog {
      width:450px;
      .el-dialog__header {
        height: 50px;
        text-align: center;
        background-color: #409eff;
      }
      .el-dialog__title {
        line-height: 0px;
        font-size: 22px;
        color: #fff;
      }
    }
  }
}
/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 181px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 181px;
  height: 100px;
  display: block;
}
</style>